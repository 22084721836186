/** @format */

import React, { useLayoutEffect, useRef, useEffect } from "react";
import { gsap, Sine  } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function SectionThree(props) {
    const containerRef = useRef(null);
    const sliderRef = useRef(null);
    // const oversizedRef = useRef(null);

    const svgWeRef = useRef(null);
    const pathWeRef = useRef(null);
    const pathWe2Ref = useRef(null);
    const pathWe3Ref = useRef(null);
    const pathWe4Ref = useRef(null);

    const svgWeRefRight = useRef(null);
    const pathWeRefRight = useRef(null);
    const pathWe2RefRight = useRef(null);
    const pathWe3RefRight = useRef(null);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const slider = sliderRef.current;
            const items = slider.querySelectorAll("p");
            let totalItemsHeight = 0;
            for (let i = 0; i < items.length; i++) {
                totalItemsHeight += items[i].clientHeight;
            }
            const tl = gsap.timeline({
                defaults: { ease: "power2.out", willChange: "transform" },
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 20%",
                    scrub: 0.5,
                    pin: true,
                    end: "+=" + totalItemsHeight,
                    invalidateOnRefresh: true,
                    willChange: "transform",
                    toggleActions: "play pause resume reset",
                    anticipatePin: 1,
                },
            });

            tl.fromTo(
                items[0],
                {
                    y: "30%",
                    willChange: "transform",
                    force3D: true,
                    opacity: 1,
                    ease: "none",
                },
                {
                    y: 0,
                    willChange: "transform",
                    force3D: true,
                    opacity: 1,
                    ease: "none",
                }
            )
            .to(items[0], {
                y: "-100%",
                willChange: "transform",
                force3D: true,
                opacity: 0,
                ease: "none",
            })
            .fromTo(
                items[1],
                {
                    y: "100%",
                    willChange: "transform",
                    force3D: true,
                    opacity: 0,
                    ease: "none",
                },
                {
                    y: 0,
                    willChange: "transform",
                    force3D: true,
                    opacity: 1,
                    ease: "none",
                },
                "-=.5"
            )
            .to(items[1], {
                y: "-100%",
                willChange: "transform",
                force3D: true,
                opacity: 1,
                ease: "none",
            });

            gsap.to(svgWeRef.current, {
                y: -180,
                willChange: "transform",
                force3D: true,
                ease: "none",
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 40%",
                    end: "+=" + totalItemsHeight,
                    scrub: true,
                },
            });
            gsap.to(pathWeRef.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=1000",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe2Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=1200",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe3Ref.current, {
                y: -350,
                x: 30,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=1300",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe4Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=1600",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });


            gsap.to(svgWeRefRight.current, {
                y: -100,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 30%",
                    end: "+=" + totalItemsHeight,
                    scrub: true,
                },
            });
            gsap.to(pathWeRefRight.current, {
                y: -250,
                x: -30,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRefRight.current,
                    start: "top bottom",
                    end: "+=1000",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe2RefRight.current, {
                y: -250,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRefRight.current,
                    start: "top bottom",
                    end: "+=1200",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe3RefRight.current, {
                y: -250,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRefRight.current,
                    start: "top bottom",
                    end: "+=1400",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });

            return () => {
                tl.kill();
            };

        });
        return () => ctx.revert();

    }, []);

    useLayoutEffect(() => {
        // Add a resize event listener
        const handleResize = () => {
            ScrollTrigger.refresh(); // Refresh the ScrollTrigger instance
        };
        window.addEventListener('resize', handleResize);
        return () => {
            // Remove the resize event listener
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
    }, []);


    return (
        <section id="cx_connecting" className="cx_connecting-section scroll-container">
            <div className="cx_connecting-wrapper" ref={containerRef}>
                <div className="cx_container">
                    <div className="cx_connecting-heading">
                        <div
                            id="cx_vertical_slider"
                            className="cx_connecting-heading__slider"
                            ref={sliderRef}
                        >
                            <p className="item0 active">We believe every brand has the potential to be a unique and evolving story, shaped by the times and technologies of its era.</p>
                            <p className="item1" style={{ opacity: 0 }}>What’s your next chapter?</p>
                        </div>
                    </div>
                </div>
                <div className="cx_left-parallax-one-group" ref={svgWeRef}>
                    <div className="cx_parallax-particles" ref={pathWeRef}></div>
                    <div className="cx_parallax-particles" ref={pathWe2Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe3Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe4Ref}></div>
                </div>
                <div className="cx_right-parallax-one-group" ref={svgWeRefRight}>
                    <div className="cx_parallax-particles" ref={pathWeRefRight}></div>
                    <div className="cx_parallax-particles" ref={pathWe2RefRight}></div>
                    <div className="cx_parallax-particles" ref={pathWe3RefRight}></div>
                </div>
            </div>
        </section>
    );
}
