import React, { useLayoutEffect, useRef } from 'react';
import { gsap, Sine } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function SectionFour(props) {
    const contentRef = useRef(null);
    const containerRef = useRef(null);

    const svgWeRef = useRef(null);
    const pathWeRef = useRef(null);
    const pathWe2Ref = useRef(null);
    const pathWe3Ref = useRef(null);
    const pathWe4Ref = useRef(null);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.from(contentRef.current, {
                y: 50,
                opacity: 0,
                duration: 1,
                delay: 0.5,
                ease: "expo.easeInOut",
                willChange: "transform",
                force3D: true,
                scrollTrigger: {
                    trigger: contentRef.current,
                    start: "top 90%",
                },
            });

            gsap.to(svgWeRef.current, {
                y: -180,
                willChange: "transform",
                force3D: true,
                ease: "none",
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 40%",
                    end: "+=2200",
                    scrub: true,
                },
            });

            gsap.to(pathWeRef.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=100%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe2Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=110%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe3Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=120%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe4Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=130%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
        });

        return () => ctx.revert();


    }, []);

    return (

        <section className="cx_innovation-section scroll-container" ref={containerRef}>
            <div className="cx_container">
                <div className="cx_generation-box" ref={contentRef}>
                    <h2 className="cx_title">Next <br />generation<br /> innovation</h2>
                    <div className="cx_text cx_animated_para" >
                        <p>New audiences, societal shifts, and transformative technologies are a catalyst for change, creating challenges and opportunities at a speed never experienced before. </p>
                        <p>Established brands are striving to adapt. Newcomers must take the mainstream on their journey. To thrive, all must be designed with strategic clarity, a unique identity, and consistent and meaningful experiences.</p>
                        <p>The very best brands will be inspired by human creativity and supercharged by technology.</p>
                    </div>
                </div>
                <div className="cx_right-parallax-two-group" ref={svgWeRef}>
                    <div className="cx_parallax-particles" ref={pathWeRef}></div>
                    <div className="cx_parallax-particles" ref={pathWe2Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe3Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe4Ref}></div>
                </div>
            </div>
        </section>

    );
}