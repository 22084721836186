/** @format */

import React, { useLayoutEffect } from "react";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import Header from "../includes/header";
import Footer from "../includes/footer";



const Layout = (props) => {

    let tl = new gsap.timeline();
    let ease = Power3.easeOut;
    
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    useLayoutEffect(() => {
        const isTouchDevice = "ontouchstart" in window;
        const smoother = ScrollSmoother.create({
            smooth: 3,
            effects: false,
            smoothTouch: 2.5,
            speed: isTouchDevice ? 0.4 : 0.6,
            ignoreMobileResize: true,
        });
        ScrollTrigger.normalizeScroll((isTouchDevice ? true : false), {
            type: "touch,wheel,pointer",
        });
        let scrollToTopClicked = false;
        const cbox = document.querySelectorAll(".cx_navbar-menu__link");
        for (let i = 0; i < cbox.length; i++) {
            cbox[i].addEventListener("click", (e) => {
                if (!scrollToTopClicked) {
                    setTimeout(() => {
                        smoother.scrollTo(e.srcElement.hash, "top 40%");
                        smoother.smooth(0.1);
                        setTimeout(() => {
                            smoother.smooth(3);
                        }, 500);
                    }, 100);
                    ScrollTrigger.refresh();
                }
            });
        }

        const Hero_arrow = document.querySelector(".cx_hero-down-arrow")
        if (Hero_arrow) {
            document.querySelector(".cx_hero-down-arrow").addEventListener("click", e => {
                setTimeout(() => {
                    smoother.scrollTo("#cx_connecting", "top 40%");
                    smoother.smooth(0.1);
                    setTimeout(() => {
                        smoother.smooth(3);
                    }, 500);
                }, 100);
                ScrollTrigger.refresh();
            });
        }

        return () => smoother.kill();
    }, []);


    return (
        <div className="App">
            <Header timeline={tl} ease={ease} headerType={props.headerType} />
            <div id="smooth-wrapper">
                <div id="smooth-content">
                    <main>{props.children}</main>
                    <Footer timeline={tl} />
                </div>
            </div>
        </div>
    );
};

export default Layout;
