import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import postlist from "../../posts.json";

import logo_white from "../../assets/images/logo-white.svg";
import logo_gradient from "../../assets/images/logo-gradient.svg";

import sky_logo from '../../assets/images/sky-icon-new.png';
import comcast_logo from '../../assets/images/comcast-icon-new.png';
import now_logo from '../../assets/images/now-icon-new.png';
import tata_play_logo from '../../assets/images/tata-icon-new.png';
import stevenbartlett_logo from '../../assets/images/steven-bartlett-icon-new.png';
import Xfinity_Logo from '../../assets/images/xfinity-icon-new.png';

import brand_img from '../../assets/images/brand_banner.png';

const relatedPosts = postlist.filter(post => post.id).slice(0, 2);


export default function LandscapePage(props) {

    const [isSticky, setIsSticky] = useState(false);

    const buttonRef = useRef(null);

    const [dropdown1Open, setDropdown1Open] = useState();

    const toggleDropdown1 = () => {
        setDropdown1Open(!dropdown1Open);
    };

    const handleReloadClick = () => {
        window.location.href();
        window.location.reload();
    };

    const [dropdownService1Open, setDropdownService1Open] = useState(false);
    const [dropdownService2Open, setDropdownService2Open] = useState(false);
    const [dropdownService3Open, setDropdownService3Open] = useState(false);

    const toggleDropdownService1 = () => {
        // const contentHeight = contentRef1.current.scrollHeight;

        setDropdownService1Open(!dropdownService1Open);
    };

    const toggleDropdownService2 = () => {
        // const contentHeight = contentRef2.current.scrollHeight;

        setDropdownService2Open(!dropdownService2Open);
    };

    const toggleDropdownService3 = () => {
        // const contentHeight = contentRef3.current.scrollHeight;

        setDropdownService3Open(!dropdownService3Open);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [dropdown1Open]);


    const handleScroll = () => {
        if (window.pageYOffset > 120) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    // Add event listeners to the menu links
    const menuLinks = document.querySelectorAll('.cx_navbar-menu-mobile .cx_navbar-menu__link:not(.not_targeted)');
    menuLinks.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault(); // Prevent the default link behavior

            // Get the target section based on the href attribute
            const targetId = link.getAttribute('href').substring(1);
            const targetSection = document.querySelector(targetId);

            // Scroll smoothly to the target section
            targetSection.scrollIntoView({
                behavior: 'auto'
            });
        });
    });

    return (
        <div className="cx_container-wrapper">
            <header className={`cx_main-header cx_ls-main-header cx_main-header--${props.headerType === "dark" ? "dark" : "light"} ${isSticky ? "cx_main-header--scrolled" : ""} ${dropdown1Open ? "cx_main-header--menuOpen" : ""}`} >
                <nav className={`cx_navbar ${dropdown1Open ? "menu-open" : ""}`}>
                    <div className="cx_nav-logo">
                        <Link to="/" className="scrollToTop">
                            <img src={logo_white} alt="Logo White" />
                        </Link>
                    </div>
                    <div className="cx_nav-logo_mobile">
                        <Link to="/" className="scrollToTop" onClick={handleReloadClick}>
                            <img src={logo_gradient} alt="Logo Gradient" />
                        </Link>
                    </div>

                    <ul className="cx_navbar-menu cx_navbar-menu-mobile">
                        <li>
                            <Link className="cx_navbar-menu__link home_target" onClick={toggleDropdown1} to="#home" >Home</Link>
                        </li>
                        <li>
                            <Link className="cx_navbar-menu__link about_target" onClick={toggleDropdown1} to="#about" >About</Link>
                        </li>
                        <li>
                            <Link className="cx_navbar-menu__link approach_target" onClick={toggleDropdown1} to="#approach"> Approach </Link>
                        </li>
                        <li>
                            <Link className="cx_navbar-menu__link not_targeted" to="/casestudy/salt"> Work </Link>
                        </li>
                        <li>
                            <Link className="cx_navbar-menu__link not_targeted" to="mailto:hello@chapterx.io" > Contact </Link>
                        </li>
                    </ul>

                    <button ref={buttonRef} type="button" className={`cx_humberburg-button ${dropdown1Open ? "active" : ""}`} onClick={toggleDropdown1} >
                        <span className="cx_humberburg__line cx_humberburg__line--top"></span>
                        <span className="cx_humberburg__line cx_humberburg__line--bottom"></span>
                    </button>
                </nav>
                <div className="cx_mobile-overlay"></div>
            </header>
            <section id="home" className="cx_ls-hero-section">
                <div className="cx_ls-container">
                    <h1 className="cx_hero-title">Creating brands <br />for a new era</h1>
                </div>
            </section>

            <section className="cx_ls-belive-section">
                <div className="cx_ls-container">
                    <h2 className="cx_connnecting-heading">We believe every brand has the potential to be a unique and evolving story, shaped by the times and technologies of its era.</h2>
                    <h2 className="cx_connnecting-heading">What’s your next chapter?</h2>
                </div>

                <div className="cx_ls-connecting-banner--left"></div>
                <div className="cx_ls-connecting-banner--right"></div>
            </section>

            <section className="cx_ls-innovation-section">
                <div className="cx_ls-container">
                    <div className="cx_content-card">
                        <h2 className="cx_ls-title">Next <br />generation <br />innovation</h2>
                        <p>New audiences, societal shifts, and transformative technologies are creating challenges and opportunities at a speed never experienced before.</p>
                        <p>Established businesses are striving to adapt. Newcomers must take the mainstream on their journey. To thrive, all must be designed with strategic clarity, a unique identity, and consistent and meaningful experiences. </p>
                        <p>The very best brands will be inspired by human creativity and supercharged by technology.</p>
                    </div>

                    <div className="cx_ls-weare-content">
                        <h2>We are independent strategists, writers, designers and experience makers</h2>
                    </div>
                </div>
                <div className="cx_ls-connecting-banner--two-right"></div>
            </section>

            <section id="about" className="cx_ls-brand-section">
                <div className="cx_ls-container">
                    <div className="cx_content-card">
                        <h2 className="cx_ls-title">Brand <br />partners</h2>
                        <p>Our team has worked with some of the world’s most ambitious clients to invent, reinvent and energise brands, inside and out.</p>
                        <p>We partner with client teams and our global network to deliver world class projects that help brands connect to people and culture.</p>
                    </div>
                    <div className="cx_ls-brand-box__inner">
                        <div className="cx_ls-brand-box__item">
                            <img src={sky_logo} alt="Sky Logo" />
                        </div>
                        <div className="cx_ls-brand-box__item">
                            <img src={comcast_logo} alt="Comcast Logo" />
                        </div>
                        <div className="cx_ls-brand-box__item">
                            <img src={now_logo} alt="Now Logo" />
                        </div>
                        <div className="cx_ls-brand-box__item">
                            <img src={stevenbartlett_logo} alt="STEVENBARTLETT Logo" />
                        </div>
                        <div className="cx_ls-brand-box__item">
                            <img src={tata_play_logo} alt="Tata Play Logo" />
                        </div>
                        <div className="cx_ls-brand-box__item">
                            <img src={Xfinity_Logo} alt="Tata Play Logo" />
                        </div>
                    </div>
                </div>
                <img src={brand_img} alt="Connecting Banner" className="cx_ls-brand-banner" />
            </section>

            <section id="approach" className="cx_ls-make-section">
                <div className="cx_ls-container">
                    <div className="cx_content-card">
                        <h2 className="cx_ls-title">Making <br />things real</h2>
                    </div>
                    <div className="cx_ls-wemake-wrapper">
                        <div className="cx_ls-wemake-list">
                            <div className={`cx_ls-wemake-item ${dropdownService1Open ? 'open' : ''}`}>
                                <div className="cx_ls-wemake-card" >
                                    <div className="cx_ls-wemake-card_header">
                                        <h2 className="cx_ls-title">Strategy</h2>
                                        <p className="cx_ls-subtitle">The clarity to power everything you do</p>
                                    </div>
                                    <div className={`cx_ls-wemake-card_body ${dropdownService1Open ? 'open' : ''}`}>
                                        <div className="cx_ls-wemake-card_body-inner">
                                            <ul className="cx_ls-wemake-list">
                                                <li>–  Business, audience and market insights</li>
                                                <li>–  Stakeholder alignment</li>
                                                <li>–  Purpose, positioning and proposition</li>
                                                <li>–  Naming and portfolio architecture</li>
                                                <li>–  Community growth; rewards and loyalty</li>
                                                <li>–  Navigating Web3, AI and metaverse</li>
                                            </ul>
                                        </div>
                                        <button type="button" className={`cx_ls-wemake-button ${dropdownService1Open ? 'active' : ''}`} onClick={toggleDropdownService1}>
                                            Read {dropdownService1Open ? 'less' : 'more'}
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path className='line-horizontal' d="M20 11H0V9H20V11Z" fill="#FF0089" />
                                                <path className='line-vertical' d="M9 20L9 0L11 0L11 20H9Z" fill="#FF0089" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={`cx_ls-wemake-item ${dropdownService2Open ? 'open' : ''}`}>
                                <div className="cx_ls-wemake-card">
                                    <div className="cx_ls-wemake-card_header">
                                        <h2 className="cx_ls-title">Design</h2>
                                        <p className="cx_ls-subtitle">The identity to make you shine</p>
                                    </div>
                                    <div className={`cx_ls-wemake-card_body ${dropdownService2Open ? 'open' : ''}`}>
                                        <div className="cx_ls-wemake-card_body-inner">
                                            <ul className="cx_ls-wemake-list">
                                                <li>–  Visual identity</li>
                                                <li>–  Verbal identity and messaging</li>
                                                <li>–  Motion, film, and sonic identity</li>
                                                <li>–  User interface</li>
                                                <li>–  System toolkits and guidelines</li>
                                            </ul>
                                        </div>

                                        <button type="button" className={`cx_ls-wemake-button ${dropdownService2Open ? 'active' : ''}`} onClick={toggleDropdownService2}>
                                            Read {dropdownService2Open ? 'less' : 'more'}
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path className='line-horizontal' d="M20 11H0V9H20V11Z" fill="#FF0089" />
                                                <path className='line-vertical' d="M9 20L9 0L11 0L11 20H9Z" fill="#FF0089" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={`cx_ls-wemake-item ${dropdownService3Open ? 'open' : ''}`}>
                                <div className="cx_ls-wemake-card">
                                    <div className="cx_ls-wemake-card_header">
                                        <h2 className="cx_ls-title">Experience</h2>
                                        <p className="cx_ls-subtitle">The real world, where it all comes to life</p>
                                    </div>
                                    <div className={`cx_ls-wemake-card_body ${dropdownService3Open ? 'open' : ''}`}>
                                        <div className="cx_ls-wemake-card_body-inner">
                                            <ul className="cx_ls-wemake-list">
                                                <li>–  Employee engagement and training</li>
                                                <li>–  Launch and activation events</li>
                                                <li>–  Film and digital communication</li>
                                                <li>–  Membership, rewards and loyalty</li>
                                                <li>–  NFT, AI and metaverse activations</li>
                                            </ul>
                                        </div>
                                        <button type="button" className={`cx_ls-wemake-button ${dropdownService3Open ? 'active' : ''}`} onClick={toggleDropdownService3}>
                                            Read {dropdownService3Open ? 'less' : 'more'}
                                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path className='line-horizontal' d="M20 11H0V9H20V11Z" fill="#FF0089" />
                                                <path className='line-vertical' d="M9 20L9 0L11 0L11 20H9Z" fill="#FF0089" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx_ls-insight-section">
                <div className="cx_ls-container">
                    <div className="cx_section-title">
                        <h1 className="cx_title cx_title--sm">Insights</h1>
                    </div>

                    <div className="cx_row">
                        {relatedPosts.map(post => (
                            <div className="cx_col cx_col-12 cx_col-md-6" key={post.id}>
                                <div className="cx_insight-card">
                                    <div className="cx_insight-card__img">
                                        <Link to={`/insights/${post.titleslug}`}>{post.thumbnail && <img className="cx_insight-img" width={80} src={post.thumbnail} alt="mathieu-chassara" />}</Link>
                                        {/* <span className="cx_insight-date">{post.date}</span> */}
                                    </div>
                                    <div className="cx_insight-card__content">
                                        <Link className="cx_insight-card__title" to={`/insights/${post.titleslug}`}>{post.title}</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="cx_ls-what-newchapter-section">
                <div className="cx_ls-container">
                    <div className="cx_logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1459.995" height="819.943" viewBox="0 0 1459.995 819.943">
                            <defs>
                                <linearGradient id="newchapt-desk-linear-gradient" y1="0.89" x2="1" y2="0.891" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#e900ce" />
                                    <stop offset="1" stopColor="#ff7d00" />
                                </linearGradient>
                            </defs>
                            <path id="Path_3" data-name="Path 3" d="M731.145,731.058l.1.053v.119h-.1ZM641.248,1.25V575.836L366.029,98.8l-77.854,44.975,287,497.467L98.956,366.1l-44.942,77.9L551.133,731.23H1.25v89.964H731.244V731.5h730V653.16H866.815L1364,365.9l-39.151-67.846L809.236,595.961,1096.261,98.454l-67.8-39.167L731.145,574.635V1.25Z" transform="translate(-1.25 -1.25)" fill="url(#newchapt-desk-linear-gradient)" />
                        </svg>
                    </div>
                    <div className="cx_ls-nextchapter">
                        <h2 className="cx_ls-nextchapter-title">What’s your next chapter? </h2>
                        <span className="cx_flashing-cursor"></span>
                    </div>
                </div>
            </section>

            <footer className="cx_main-footer cx_ls-main-footer">
                <div className="cx_container">
                    <div className="cx_row">
                        <div className="cx_footer-left">
                            <h2 className="cx_footer-title">We’d love to help you create it</h2>
                            <Link to="mailto:hello@chapterx.io" className="cx_footer-email">hello@chapterx.io</Link>

                            <p className="cx_footer-copyright">©ChapterX 2023</p>
                        </div>
                        <div className="cx_footer-right">
                            <div className="cx_footer-social">
                                <div className="cx_footer-social__item">
                                    <Link to="https://www.linkedin.com/company/chapterx-ltd" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28.81" height="28.81" viewBox="0 0 28.81 28.81">
                                            <path id="linkedin" d="M26.752,32H2.051A2.067,2.067,0,0,0,0,34.077V58.732A2.067,2.067,0,0,0,2.051,60.81h24.7a2.072,2.072,0,0,0,2.058-2.077V34.077A2.072,2.072,0,0,0,26.752,32ZM8.707,56.694H4.437V42.945H8.714V56.694ZM6.572,41.067a2.476,2.476,0,1,1,2.476-2.476A2.477,2.477,0,0,1,6.572,41.067ZM24.713,56.694h-4.27V50.006c0-1.595-.032-3.646-2.219-3.646-2.225,0-2.566,1.736-2.566,3.53v6.8h-4.27V42.945h4.1v1.878h.058A4.5,4.5,0,0,1,19.588,42.6c4.321,0,5.125,2.849,5.125,6.553Z" transform="translate(0 -32)" fill="#ff0089" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>

                            <ul className="cx_footer-menu">
                                <li className="cx_footer-menu__item">
                                    <Link to="/privacyPolicy" className="cx_footer-menu__link">privacy policy</Link>
                                </li>
                                <li className="cx_footer-menu__item">
                                    <Link to="/termsconditions" className="cx_footer-menu__link">terms and conditions</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}