import React, { useEffect, useRef } from 'react';
import { Navigate, useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import Layout from "../common/layout";

import postlist from "../../posts.json"

gsap.registerPlugin(ScrollToPlugin);

export default function Single(props) {
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    const { id } = useParams()

    useEffect(() => {

        let ctx = gsap.context(() => {
            if (contentRef.current) {
                gsap.from(contentRef.current, {
                    opacity: 0,
                    duration: 1,
                    delay: 0.2,
                    ease: "expo.easeInOut",
                    // scrollTrigger: {
                    //     trigger: containerRef.current,
                    //     start: "top 90%",
                    // },
                });
            }
        });

        return () => ctx.revert();


    }, []);

    if (!id) {
        return <Navigate to="/404" />
    }
    const fetchedPost = {}
    let postExists = false
    postlist.forEach((post, i) => {
        if (id === post.titleslug.trim()) {
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.date = post.date ? post.date : "No date given"
            fetchedPost.author = post.author ? post.author : "No author given"
            fetchedPost.content = post.content ? post.content : "No content given"
            fetchedPost.thumbnail = post.thumbnail ? post.thumbnail : "No thumbnail given"
            postExists = true
        }
    })
    if (postExists === false) {
        return <Navigate to="/404" />
    }

    const currentPost = postlist.find(post => post.titleslug.trim() === id);

    if (!currentPost) {
        return <Navigate to="/404" />
    }

    const relatedPosts = postlist.filter(post => post.id !== currentPost.id).slice(0, 4);

    return (
        <Layout headerType="dark">
            <section className="cx_insight-section cx_insight-single-section cx_content-section" ref={containerRef}>
                <div className="cx_container" ref={contentRef}>
                    <div className="cx_insight-card">
                        <div className="cx_insight-card__img">
                            {fetchedPost.thumbnail && <img className="cx_insight-img" src={fetchedPost.thumbnail} alt="mathieu-chassara" />}
                            <span className="cx_insight-date">{fetchedPost.date}</span>
                        </div>
                        <div className="cx_insight-card__content">
                            <div className="cx_row">
                                <div className="cx_col cx_col-12 cx_col-md-10">
                                    <h2 className="cx_title">{fetchedPost.title}</h2>
                                    <ReactMarkdown>{fetchedPost.content}</ReactMarkdown>
                                    {/* {fetchedPost.content} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx_insight-related-section">
                <div className="cx_container">
                    <div className="cx_section-title">
                        <h1 className="cx_title cx_title--sm">More Articles</h1>
                    </div>

                    <div className="cx_row">
                    {relatedPosts.map(post => (
                        <div className="cx_col cx_col-12 cx_col-md-6" key={post.id}>
                            <div className="cx_insight-card">
                                <div className="cx_insight-card__img">
                                    <Link to={`/insights/${post.titleslug}`}>{post.thumbnail && <img className="cx_insight-img" width={80} src={post.thumbnail} alt="mathieu-chassara" />}</Link>
                                    {/* <span className="cx_insight-date">{post.date}</span> */}
                                </div>
                                <div className="cx_insight-card__content">
                                    <Link className="cx_insight-card__title" to={`/insights/${post.titleslug}`}>{post.title}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </section>
        </Layout>
    );
}