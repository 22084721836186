/** @format */

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/home/index";
import LandscapePage from "./components/landscape/LandscapePage";
import Post from "./components/pages/Post";
import Insights from "./components/insights/index";
import InsightsSingle from "./components/insights/single";
import Casestudies from "./components/casestudies/index";
import CasestudySingle from "./components/casestudies/single";
import NotFound from "./components/pages/NotFound";
import Comingsoon from "./components/pages/Comingsoon";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsConditions from "./components/pages/TermsConditions";

import "./assets/scss/App.scss";
import ScrollToTop from "./components/hooks/ScrollToTop";
import OrientationFixer from "./components/hooks/OrientationFixer";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <OrientationFixer />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/landscape" element={LandscapePage} />
        <Route exact path="/insights" element={<Insights />}></Route>
        <Route exact path="/insights/:id" element={<InsightsSingle />}></Route>
        <Route exact path="/post/:id" element={<Post />} />
        <Route exact path="/casestudies" element={<Casestudies />} />
        <Route exact path="/casestudy/:id" element={<CasestudySingle />} />
        <Route exact path="/404" element={<NotFound />} />
        <Route exact path="/comingsoon" element={<Comingsoon />} />
        <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/termsconditions" element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;