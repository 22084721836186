import React from 'react';
import { Link } from "react-router-dom"
import Layout from "../common/layout";
import postlist from "../../posts.json"

export default function Index(props) {

    return (
        <Layout headerType="dark">
            <section className="cx_insight-section cx_content-section">
                <div className="cx_container">
                    <div className="cx_section-title">
                        <h1 className="cx_title">Insights</h1>
                    </div>

                    <div className="cx_row">
                        {postlist.length &&
                            postlist.map((post, i) => {
                                return (
                                    <div className="cx_col cx_col-12 cx_col-md-6" key={i}>
                                        <div className="cx_insight-card">
                                            <div className="cx_insight-card__img">
                                                <Link to={`/insights/${post.titleslug}`}>{post.thumbnail && <img className="cx_insight-img" width={80} src={post.thumbnail} alt="mathieu-chassara" />}</Link>
                                                {/* <span className="cx_insight-date">{post.date}</span> */}
                                            </div>
                                            <div className="cx_insight-card__content">
                                                <Link className="cx_insight-card__title" to={`/insights/${post.titleslug}`}>{post.title}</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </Layout>
    );
}