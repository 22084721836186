import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

export default function SectionSeven(props) {
    const component = useRef(null);

    const textRef = useRef(null);
    const containerRef = useRef(null);
    const myRef = useRef(null);

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);

    const [dropdown1Open, setDropdown1Open] = useState(false);
    const [dropdown2Open, setDropdown2Open] = useState(false);
    const [dropdown3Open, setDropdown3Open] = useState(false);
    const isMobile = window.innerWidth < 768;

    const toggleDropdown1 = () => {
        const contentHeight = contentRef1.current.scrollHeight;
        gsap.to(contentRef1.current, {
            duration: 0.5,
            height: dropdown1Open ? 0 : contentHeight,
            ease: 'power3.inOut',
        });
        setDropdown1Open(!dropdown1Open);
        if (isMobile) {
            if (dropdown2Open) {
                const contentHeight = contentRef2.current.scrollHeight;
                gsap.to(contentRef2.current, {
                    duration: 0.5,
                    height: dropdown2Open ? 0 : contentHeight,
                    ease: 'power3.inOut',
                });
                setDropdown2Open(!dropdown2Open);
            }
            if (dropdown3Open) {
                const contentHeight = contentRef3.current.scrollHeight;
                gsap.to(contentRef3.current, {
                    duration: 0.5,
                    height: dropdown3Open ? 0 : contentHeight,
                    ease: 'power3.inOut',
                });
                setDropdown3Open(!dropdown3Open);
            }
        }
    };

    const toggleDropdown2 = () => {
        const contentHeight = contentRef2.current.scrollHeight;
        gsap.to(contentRef2.current, {
            duration: 0.5,
            height: dropdown2Open ? 0 : contentHeight,
            ease: 'power3.inOut',
        });
        setDropdown2Open(!dropdown2Open);
        if (isMobile) {
            if (dropdown1Open) {
                const contentHeight = contentRef1.current.scrollHeight;
                gsap.to(contentRef1.current, {
                    duration: 0.5,
                    height: dropdown1Open ? 0 : contentHeight,
                    ease: 'power3.inOut',
                });
                setDropdown1Open(!dropdown1Open);
            }
            if (dropdown3Open) {
                const contentHeight = contentRef3.current.scrollHeight;
                gsap.to(contentRef3.current, {
                    duration: 0.5,
                    height: dropdown3Open ? 0 : contentHeight,
                    ease: 'power3.inOut',
                });
                setDropdown3Open(!dropdown3Open);
            }
        }
    };

    const toggleDropdown3 = () => {
        const contentHeight = contentRef3.current.scrollHeight;
        gsap.to(contentRef3.current, {
            duration: 0.5,
            height: dropdown3Open ? 0 : contentHeight,
            ease: 'power3.inOut',
        });
        setDropdown3Open(!dropdown3Open);
        if (isMobile) {
            if (dropdown1Open) {
                const contentHeight = contentRef1.current.scrollHeight;
                gsap.to(contentRef1.current, {
                    duration: 0.5,
                    height: dropdown1Open ? 0 : contentHeight,
                    ease: 'power3.inOut',
                });
                setDropdown1Open(!dropdown1Open);
            }
            if (dropdown2Open) {
                const contentHeight = contentRef2.current.scrollHeight;
                gsap.to(contentRef2.current, {
                    duration: 0.5,
                    height: dropdown2Open ? 0 : contentHeight,
                    ease: 'power3.inOut',
                });
                setDropdown2Open(!dropdown2Open);
            }
        }
    };

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.from(textRef.current, {
                opacity: 0,
                y: 50,
                duration: 1,
                delay: 0,
                willChange: "transform",
                force3D: true,
                ease: "expo.easeInOut",
                scrollTrigger: {
                    trigger: textRef.current,
                    start: "top 100%",
                },
            });

        }, component.current);
        return () => ctx.revert();

    }, []);

    useEffect(() => {
        const buttons = gsap.utils.toArray('.cx_wemake-card');
        buttons.forEach((btn) => {
            gsap.from(btn, {
                duration: 1,
                delay: 2,
                y: 0,
                willChange: "transform",
                force3D: true,
                scrollTrigger: {
                    start: 'top 90%',
                    end: 'bottom top',
                    trigger: btn,
                    toggleClass: 'is_visible',
                    scrub: 1,
                }
            });
        });

    }, []);

    return (

        <section id="approach" className="cx_make-section scroll-container" ref={containerRef}>
            <div className="cx_container">
                <h2 className="cx_section-title" ref={textRef}>Making <br />things real</h2>
                <div className="cx_we-make-wrapper">
                    <div className="cx_we-make-list">
                        <div className={`cx_we-make-item ${dropdown1Open ? 'open' : ''}`}>
                            <div className="cx_wemake-card" >
                                <div className="cx_wemake-card_header">
                                    <h2 className="cx_title">Strategy</h2>
                                    <p className="cx_subtitle">The clarity to power everything you do</p>
                                </div>
                                <div className={`cx_wemake-card_body ${dropdown1Open ? 'open' : ''}`}>
                                    <div className="cx_wemake-card_body-inner" ref={contentRef1}>
                                        <ul className="cx_wemake-list">
                                            <li>–  Business, audience & market insights</li>
                                            <li>–  Exec & stakeholder alignment </li>
                                            <li>–  Purpose, positioning & proposition</li>
                                            <li>–  Naming & portfolio architecture</li>
                                            <li>–  Community growth, rewards & loyalty </li>
                                            <li>–  Navigating Web3 & Metaverse</li>
                                        </ul>
                                    </div>
                                    <button type="button" className={`cx_wemake-button ${dropdown1Open ? 'active' : ''}`} onClick={toggleDropdown1}>
                                        Read {dropdown1Open ? 'less' : 'more'}
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path className='line-horizontal' d="M20 11H0V9H20V11Z" fill="#FF0089" />
                                            <path className='line-vertical' d="M9 20L9 0L11 0L11 20H9Z" fill="#FF0089" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={`cx_we-make-item ${dropdown2Open ? 'open' : ''}`}>
                            <div className="cx_wemake-card" ref={myRef}>
                                <div className="cx_wemake-card_header">
                                    <h2 className="cx_title">Design</h2>
                                    <p className="cx_subtitle">The identity to make you shine</p>
                                </div>
                                <div className={`cx_wemake-card_body ${dropdown2Open ? 'open' : ''}`}>
                                    <div className="cx_wemake-card_body-inner" ref={contentRef2}>
                                        <ul className="cx_wemake-list">
                                            <li>–  Visual identity & systems</li>
                                            <li>–  Verbal identity & messaging</li>
                                            <li>–  Motion, film & sonic identity</li>
                                            <li>–  User experience & interface</li>
                                            <li>–  System toolkits & guidelines</li>
                                            <li>–  Toolkits & guidelines</li>
                                        </ul>
                                    </div>
                                    <button type="button" className={`cx_wemake-button ${dropdown2Open ? 'active' : ''}`} onClick={toggleDropdown2}>
                                        Read {dropdown2Open ? 'less' : 'more'}
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path className='line-horizontal' d="M20 11H0V9H20V11Z" fill="#FF0089" />
                                            <path className='line-vertical' d="M9 20L9 0L11 0L11 20H9Z" fill="#FF0089" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={`cx_we-make-item ${dropdown3Open ? 'open' : ''}`}>
                            <div className="cx_wemake-card" ref={myRef}>
                                <div className="cx_wemake-card_header">
                                    <h2 className="cx_title">Experience</h2>
                                    <p className="cx_subtitle">The real world, where it all comes to life</p>
                                </div>
                                <div className={`cx_wemake-card_body ${dropdown3Open ? 'open' : ''}`}>
                                    <div className="cx_wemake-card_body-inner" ref={contentRef3}>
                                        <ul className="cx_wemake-list">
                                            <li>–  Employee engagement & training</li>
                                            <li>–  Launch & activation events</li>
                                            <li>–  Film & digital communication</li>
                                            <li>–  Membership, rewards & loyalty</li>
                                            <li>–  NFT & Metaverse activations</li>
                                            <li className="empty"></li>
                                        </ul>
                                    </div>
                                    <button type="button" className={`cx_wemake-button ${dropdown3Open ? 'active' : ''}`} onClick={toggleDropdown3}>
                                        Read {dropdown3Open ? 'less' : 'more'}
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path className='line-horizontal' d="M20 11H0V9H20V11Z" fill="#FF0089" />
                                            <path className='line-vertical' d="M9 20L9 0L11 0L11 20H9Z" fill="#FF0089" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}  