import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Navigation, Pagination } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';


import Layout from "../common/layout";

import casestudylist from "../../casestudies.json";
import first_video from '../../assets/video/Video_1_Salt_Symbol_Network_Loop_1.mp4';
import second_video from '../../assets/video/Video-2-6_Logo_Reveal_Short.mp4';
import third_video from '../../assets/video/Video-3-Salt Loop_1920.mp4';
import four_video from '../../assets/video/Video-4-Salt_Brand_Reel_V07.mp4';
import five_video from '../../assets/video/Video-5-Artist_Frames-Grey.mp4';
import six_image from '../../assets/images/Video-6-Icons-Animated_2.gif';
import six_mobile_image from '../../assets/images/Video-6-Icons-Animated-mobile.gif';
import seven_video from '../../assets/video/Video-7-SALT_PRODUCT_ARCHITECTURE_V03 B.mp4';
import eight_video from '../../assets/video/Video-8-Salt_Brand_Reel_A_Part2.mp4';

import Salt_Report_Cover from '../../assets/images/Salt-Report-Cover.jpg';
import Salt_Office from '../../assets/images/Salt-Office.jpg';
import Salt_Apple_Wallet from '../../assets/images/Salt-Apple-Wallet-2.jpg';
import Salt_Mixing_desk from '../../assets/images/Salt-Mixing-desk.jpg';
import Salt_Pass from '../../assets/images/Salt-Pass.jpg';
import Salt_website from '../../assets/images/Salt-website.jpg';
// import salt_sessions_logo from '../../assets/images/salt-sessions-logo.png';

import "./casestudies-single.scss";
import 'swiper/css';


gsap.registerPlugin(ScrollToPlugin);

export default function Single(props) {
    const contentRef = useRef(null);
    const containerRef = useRef(null);
    const { id } = useParams();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);


        let ctx = gsap.context(() => {
            if (contentRef.current) {
                gsap.from(contentRef.current, {
                    opacity: 0,
                    duration: 1,
                    delay: 0.2,
                    ease: "expo.easeInOut",
                });
            }
        });

        const videos = document.querySelectorAll('.cx_video-item');

        videos.forEach((video) => {
            const section = video.closest('.cx_cs_video');

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: 'top 50%',
                    end: 'bottom 20%',
                    scrub: true,
                    onEnter: () => {
                        video.play();
                        video.classList.add('play');
                    },
                    onLeave: () => {
                        video.pause();
                        video.classList.remove('play');
                    },
                },
            });

            // Add any additional animations or effects to the timeline if needed
            window.addEventListener('resize', handleResize);

            // Cleanup function for removing ScrollTrigger on unmount
            return () => {
                tl.kill();
                ScrollTrigger.getById(section);
                ctx.revert();
                window.removeEventListener('resize', handleResize);
            };
        });

    }, []);

    if (!id) {
        return <Navigate to="/404" />
    }
    const fetchedPost = {}
    let postExists = false
    casestudylist.forEach((post, i) => {
        if (id === post.titleslug.trim()) {
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.date = post.date ? post.date : "No date given"
            fetchedPost.author = post.author ? post.author : "No author given"
            fetchedPost.content = post.content ? post.content : "No content given"
            fetchedPost.thumbnail = post.thumbnail ? post.thumbnail : "No thumbnail given"
            postExists = true
        }
    })
    if (postExists === false) {
        return <Navigate to="/404" />
    }

    // Check if the device width is less than a certain value (e.g., 768 for mobile)

    // Render images directly for mobile devices
    const imagesForMobile = isMobile && (
        <div className="cx_carousel--images">
            <div className="cx_ratio cx_ratio-16x9">
                <img src={Salt_Report_Cover} className="cx_img-fluid" alt="Salt Report Cover" />
            </div>
            <div className="cx_ratio cx_ratio-16x9">
                <img src={Salt_Apple_Wallet} className="cx_img-fluid" alt="Salt apple wallet" />
            </div>
            <div className="cx_ratio cx_ratio-16x9">
                <img src={Salt_Mixing_desk} className="cx_img-fluid" alt="Salt mixing desk" />
            </div>
            <div className="cx_ratio cx_ratio-16x9">
                <img src={Salt_Office} className="cx_img-fluid" alt="Salt Office" />
            </div>
            <div className="cx_ratio cx_ratio-16x9">
                <img src={Salt_Pass} className="cx_img-fluid" alt="Salt Pass" />
            </div>
        </div>
    );

    // Conditionally render Swiper only for non-mobile devices
    const swiperComponent = !isMobile ? (
        <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={20}
            slidesPerView={1.5}
            centeredSlides={true}
            navigation
            pagination={{ clickable: true }}
            loop={true}
            breakpoints={{
                230: {
                    spaceBetween: 10,
                },
                1200: {
                    spaceBetween: 40,
                },
            }}>
            <SwiperSlide>
                <div className="cx_ratio cx_ratio-16x9">
                    <img src={Salt_Report_Cover} className="cx_img-fluid" alt="Salt Report Cover" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cx_ratio cx_ratio-16x9">
                    <img src={Salt_Apple_Wallet} className="cx_img-fluid" alt="Salt apple wallet" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cx_ratio cx_ratio-16x9">
                    <img src={Salt_Mixing_desk} className="cx_img-fluid" alt="Salt mixing desk" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cx_ratio cx_ratio-16x9">
                    <img src={Salt_Office} className="cx_img-fluid" alt="Salt Office" />
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="cx_ratio cx_ratio-16x9">
                    <img src={Salt_Pass} className="cx_img-fluid" alt="Salt Pass" />
                </div>
            </SwiperSlide>
        </Swiper>
    ) : null;

    return (
        <Layout headerType="black">
            <section className="cx_cs-single-section" ref={containerRef}>
                <div className="cx_container" ref={contentRef}>
                    <h2 className="cx_title">Set the record straight </h2>
                </div>
                <div className="cx_ratio cx_ratio-100vh">
                    <video autoPlay muted loop playsInline>
                        <source src={first_video}></source>
                    </video>
                </div>
            </section>

            <section id="cx_connecting" className="cx_single-content-section ">
                <div className="cx_container">
                    <div className="cx_text">
                        <h3 className="cx_title">Salt</h3>
                        <p className="cx_subtitle">Positioning <br /> Architecture <br /> Identity</p>
                    </div>
                    <div className="cx_row">
                        <div className="cx_col cx_col-12 cx_col-lg-5">
                            <div className="cx_description">
                                <p>Music is one of the purest forms of creativity. But the system for paying artists is stuck in the pre-digital era, struggling to keep up with 120,000 tracks released on streaming platforms every day. </p>
                                <p>Salt is here to change all that.</p>
                                <p>Developed and backed by acclaimed names in music and technology, Salt’s music royalties network promises faster, fairer payments for rights holders, artists and music societies everywhere. But they needed a brand to drive their next phase of growth.  </p>
                            </div>
                        </div>
                        <div className="cx_col cx_col-12 cx_col-lg-5">
                            <div className="cx_description">
                                <p>We saw an opportunity for Salt to stand for more than the brilliance of their technology, the speed of their payments, or even the music industry itself. Fundamentally, Salt sets the record straight for the music industry to make sure creativity is fairly rewarded.</p>
                                <p>Working side-by-side with its founding partners, we helped Salt take this story to the world with a brand strategy, architecture, visual identity and messaging as creatively pure as music itself.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx_single-media-section cx_cs_video">
                <div className="cx_ratio cx_ratio-19x10">
                    <video className="cx_video-item" loop muted playsInline preload="metadata">
                        <source src={second_video}></source>
                    </video>
                </div>
            </section>

            <section className="cx_single-testimonial-section">
                <div className="cx_container">
                    <div className="cx_testimonial-content">
                        <h2 className="cx_content">“I was instantly struck by the otherworldliness of our new logo. It’s a perfect balance of simplicity and depth.”</h2>
                        <p className="cx_name">Doug Imrie, Founder</p>
                    </div>
                </div>
            </section>

            <section className="cx_single-media-section cx_cs_video cx_bg-black">
                <div className="cx_ratio cx_ratio-19x10">
                    <video className="cx_video-item" loop muted playsInline preload="metadata">
                        <source src={four_video}></source>
                    </video>
                </div>
            </section>

            <section className="cx_single-media-section cx_pb-0 cx_pt-40 cx_cs_video">
                <div className="cx_ratio cx_ratio-19x10">
                    <video className="cx_video-item" loop muted playsInline preload="metadata">
                        <source src={five_video}></source>
                    </video>
                </div>
            </section>

            <section className="cx_single-media-section cx_pb-0 cx_pt-40 cx_cs_video">
                <div className="cx_ratio cx_ratio-19x10">
                    <video className="cx_video-item" loop muted playsInline preload="metadata">
                        <source src={third_video}></source>
                    </video>
                </div>
            </section>

            <section className="cx_single-carousel-section cx_pt-40">
                <div className="cx_single-carousel">
                    {isMobile ? imagesForMobile : swiperComponent}
                </div>
            </section>

            <section className="cx_single-testimonial-section">
                <div className="cx_container">
                    <div className="cx_testimonial-content">
                        <h2 className="cx_content">“The right brand can do so much for the business and the energy in the business. Finding a team who can develop a kick-ass global brand that will cut through and last the test of time is far from easy. ChapterX are brand gurus.”</h2>
                        <p className="cx_name">Phil Sant, Founder</p>
                    </div>
                </div>
            </section>

            <section className="cx_single-gallery-section cx_pb-0 cx_cs_video">
                <div className="cx_row">
                    <div className="cx_col cx_col-12">
                        <div className="cx_ratio cx_ratio-19x10">
                            <img src={Salt_website} className="cx_img-fluid cx_media-cover" alt="Salt Pass" />
                        </div>
                    </div>
                    <div className="cx_col cx_col-12 cx_col-md-6">
                        <div className="cx_ratio cx_ratio-1x1 cx_bg-black hide-mobile">
                            <img src={six_image} className="cx_img-fluid cx_media-scale-down" alt="Salt Sessions Logo" />
                        </div>
                        <div className="cx_ratio cx_ratio-1x1 cx_bg-black show-mobile">
                            <img src={six_mobile_image} className="cx_img-fluid cx_media-scale-down" alt="Salt Sessions Logo" />
                        </div>
                    </div>
                    <div className="cx_col cx_col-12 cx_col-md-6">
                        {/* <div className="cx_ratio cx_ratio-1x1">
                            <img src={salt_sessions_logo} className="cx_img-fluid cx_media-scale-down" alt="Salt Sessions Logo" />
                        </div> */}
                        <div className="cx_ratio cx_ratio-1x1 cx_bg-white">
                            <video className="cx_video-item" loop muted playsInline preload="metadata">
                                <source src={seven_video}></source>
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cx_single-media-section cx_pt-40 cx_cs_video">
                <div className="cx_col cx_col-12">
                    <div className="cx_ratio cx_ratio-16x9">
                        <video className="cx_video-item" loop muted playsInline preload="metadata">
                            <source src={eight_video}></source>
                        </video>
                    </div>
                </div>
            </section>

            <section className="cx_thankyou-section">
                <div className="cx_container">
                    <div className="cx_row">
                        <div className="cx_col cx_col-12 cx_col-md-6">
                            <h2 className="cx_title">Thank you</h2>
                            <p className="cx_description">A big thanks to the creators, investors, Doug and Phil and the Salt team.</p>
                            <p className='cx_news_title'>Salt in the news</p>
                            <p className="cx_description">We’re delighted to hear about Salt’s latest milestone. Their <a href="https://saltmusic.io/pdf/Salt-Release-MLC.pdf" rel="noopener noreferrer" target="_BLANK">new partnership with US music collection society, MLC</a> comes hot on the heels of a <a href="https://saltmusic.io/pdf/Salt-Release-Buma-Stemra.pdf" rel="noopener noreferrer" target="_BLANK">10-year deal with Dutch music society Buma/Stemra</a>.</p>
                        </div>
                        <div className="cx_col cx_col-12 cx_col-md-6">
                            {/* <Link to="/" className="cx_link cx_link-primary">Next Project</Link> */}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}