import { useEffect, useState } from "react";
import "./LandscapePage.scss";


const useOrientation = () => {
    const [isMobileLandscape, setIsMobileLandscape] = useState(false);

    const handleOrientationChange = (event) => {
        setIsMobileLandscape(event.matches);
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia(
            "(orientation: landscape) and (max-width: 991px)"
        );
        setIsMobileLandscape(mediaQuery.matches);
        mediaQuery.addEventListener("change", handleOrientationChange);

        return () => {
            mediaQuery.removeEventListener("change", handleOrientationChange);
        };
    }, []);

    return isMobileLandscape;
};

export default useOrientation;