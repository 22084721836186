import React, { useLayoutEffect, useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { Link } from "react-router-dom";

import Lottie from 'lottie-react';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import animation1 from '../../assets/images/Desktop-Home01.json';
import animation2 from '../../assets/images/Desktop-Home02.json';
import animationMobile1 from '../../assets/images/Mobile-Home01.json';
import animationMobile2 from '../../assets/images/Mobile-Home02.json';
import ArrowLoop from '../../assets/images/Arrow_Loop.json';


gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
export default function SectionOne(props) {
    const [opacity, setOpacity] = useState(0);
    const [opacity1, setOpacity1] = useState(1);

    const [showAnimation, setShowAnimation] = useState(false);
    const [showArrow, setShowArrow] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useLayoutEffect(() => {
        const tl = gsap.timeline({ defaults: { delay: 0, duration: 1, ease: 'Power4.easeIn', } });
        tl.to('.cx_overlay', { force3D: true, right: 0 })
            .to('.cx_overlay', { force3D: true, height: '100%' }, '-=.1')
    }, []);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpacity(1);
            setOpacity1(0);
        }, 9500);
        
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const firstSection = document.getElementById('cx_connecting');
            if (firstSection != null) {
            if (scrollTop >= firstSection.offsetTop + 750) {
                setIsScrolled(true);
            } 
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    setTimeout(() => {
        setShowAnimation(true);
    }, 2500);
    setTimeout(() => {
        setShowArrow(true);
    }, 9500);
    return (
        <section id="home" className="cx_hero-section scroll-container">
            <div className="cx_overlay first"></div>
            <div className={`cx_hero-content ${isScrolled ? 'onScrolledTitle' : ''}`}>
                <div className="cx_hero-icon-desktop">
                    {showAnimation && (
                        <Lottie
                            className="cx_hero-lottie-first"
                            animationData={animation1}
                            autoplay={true}
                            loop={false}
                            style={{ opacity: opacity1 }}
                        />
                    )}
                    {showAnimation && (
                        <Lottie
                            style={{ opacity: opacity }}
                            className="cx_hero-lottie-two"
                            animationData={animation2}
                            autoplay={true}
                            loop={true}
                        />
                    )}
                </div>
                <div className="cx_hero-icon-mobile">
                    {showAnimation && (
                        <Lottie
                            className="cx_hero-lottie-first"
                            animationData={animationMobile1}
                            autoplay={true}
                            loop={false}
                            style={{ opacity: opacity1 }}
                        />
                    )}
                    {showAnimation && (
                        <Lottie
                            style={{ opacity: opacity }}
                            className="cx_hero-lottie-two"
                            animationData={animationMobile2}
                            autoplay={true}
                            loop={true}
                        />
                    )}
                </div>

                <h1>Creating brands for a new era</h1>
            </div>
            <Link className="cx_hero-down-arrow" to="/#cx_connecting" style={{ opacity: opacity }}>
                {showArrow && (
                    <Lottie className="cx_hero-down-arrow__svg" animationData={ArrowLoop} autoplay={true} loop={true} />
                )}
            </Link>
        </section>
    );
}