import React, { } from 'react';

import sky_logo from '../../assets/images/sky-icon-new.png';
import comcast_logo from '../../assets/images/comcast-icon-new.png';
import now_logo from '../../assets/images/now-icon-new.png';
import tata_play_logo from '../../assets/images/tata-icon-new.png';
import salt_logo from '../../assets/images/salt.png';
import Xfinity_Logo from '../../assets/images/xfinity-icon-new.png';

export default function SectionSix(props) {
    return (
        <section id="about" className="cx_brand-section scroll-container">
            <div className="cx_brand-section__wrapper">
                <div className="cx_container">
                    <div className="cx_row">
                        <div className="cx_brandtext-box cx_col-12 cx_col-lg-5">

                            <h2 className="cx_title">Strategic<br/> brand<br/> partners</h2>
                            <div className="cx_text cx_brand_para">
                                <p>We are a brand’s conscience. Trusted advisors. Inspiration partners. We have worked with the world’s most ambitious clients to invent, reinvent, and energise brands, inside and out, creating meaningful, impactful change. </p>
                                <p>In partnership with client teams and our global creative network we join the dots between boardrooms and real-world experiences. We help brands to grow and connect to people and culture.</p>
                            </div>
                        </div>

                        <div className="cx_brand-box cx_col-12 cx_col-lg-7">
                            <div className="cx_brand-box__inner">
                                <div className="cx_brand-box__item">
                                    <img src={sky_logo} alt="Sky Logo" />
                                </div>
                                <div className="cx_brand-box__item">
                                    <img src={comcast_logo} alt="Comcast Logo" />
                                </div>
                                <div className="cx_brand-box__item">
                                    <img src={now_logo} alt="Now Logo" />
                                </div>
                                <div className="cx_brand-box__item">
                                    <img src={salt_logo} alt="STEVENBARTLETT Logo" />
                                </div>
                                <div className="cx_brand-box__item">
                                    <img src={tata_play_logo} alt="Tata Play Logo" />
                                </div>
                                <div className="cx_brand-box__item">
                                    <img src={Xfinity_Logo} alt="Tata Play Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}