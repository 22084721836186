import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function SectionEight(props) {

    const textNextRef = useRef(null);
    const component = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {

        
        let ctx = gsap.context(() => {
            
            gsap.from(textNextRef.current, {
                duration: 1,
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 98%",
                    end: "bottom 85%",
                    scrub: 1,
                },
                y: -152,
                willChange: "transform",
                force3D: true,
                delay: 1,
                opacity: 0,
                stagger: 0.1,
                ease: 'Power4.easeOut',
            });

            gsap.from('.cx_logo', {
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top bottom",
                    end: "bottom 100%",
                    scrub: true,
                },
                opacity: 1,
                scale: 0.5,
                willChange: "transform",
                force3D: true,
                delay: 2,
                ease: "Power2.out",
                duration: 0.5
            });
            
            gsap.from('.cx_logo_mobile', {
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top bottom",
                    end: "bottom 100%",
                    scrub: true,
                },
                opacity: 0,
                willChange: "transform",
                force3D: true,
                delay: 1,
                ease: "Power2.out",
                duration: 0.5
            });
        }, component.current);

        return () => ctx.revert();
    }, []);


    return (

        <section className="cx_what-newchapter-section scroll-container" ref={containerRef}>
            <div className="cx_logo hide-mobile show-desktop">
                <svg xmlns="http://www.w3.org/2000/svg" width="1459.995" height="819.943" viewBox="0 0 1459.995 819.943">
                    <defs>
                        <linearGradient id="newchapt-desk-linear-gradient" y1="0.89" x2="1" y2="0.891" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#e900ce" />
                            <stop offset="1" stopColor="#ff7d00" />
                        </linearGradient>
                    </defs>
                    <path id="Path_3" data-name="Path 3" d="M731.145,731.058l.1.053v.119h-.1ZM641.248,1.25V575.836L366.029,98.8l-77.854,44.975,287,497.467L98.956,366.1l-44.942,77.9L551.133,731.23H1.25v89.964H731.244V731.5h730V653.16H866.815L1364,365.9l-39.151-67.846L809.236,595.961,1096.261,98.454l-67.8-39.167L731.145,574.635V1.25Z" transform="translate(-1.25 -1.25)" fill="url(#newchapt-desk-linear-gradient)" />
                </svg>
            </div>

            <div className="cx_logo_mobile show-mobile hide-desktop">
                <svg xmlns="http://www.w3.org/2000/svg" width="390" height="403" viewBox="0 0 390 403">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_58" data-name="Rectangle 58" width="390" height="403" fill="#fff" />
                        </clipPath>
                        <linearGradient id="newchapt-mobile-linear-gradient" y1="1" x2="1" y2="1" gradientUnits="objectBoundingBox">
                            <stop offset="0" stopColor="#e900ce" />
                            <stop offset="1" stopColor="#ff7d00" />
                        </linearGradient>
                    </defs>
                    <g id="Mask_Group_1" data-name="Mask Group 1" clipPath="url(#clip-path)">
                        <path id="Path_5" data-name="Path 5" d="M360.2,359.9l.049.026v.058H360.2ZM315.988,1.25V283.618L180.641,49.191l-38.287,22.1,141.14,244.469L49.3,180.548,27.2,218.833l244.473,141.15H1.25v44.211h359V360.116h359v-38.5H426.917L671.422,180.448l-19.254-33.341L398.6,293.508,539.753,49.019l-33.34-19.248L360.2,283.027V1.25Z" transform="translate(29.752 -1.25)" fill="url(#newchapt-mobile-linear-gradient)" />
                    </g>
                </svg>
            </div>

            <div className="cx_container">
                <h2 className="cx_nextchapter-title" ref={textNextRef}>What’s your next chapter? </h2>
                <span className="cx_flashing-cursor"></span>
            </div>
        </section>

    );
}