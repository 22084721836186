/** @format */

import React, { useEffect, useRef, useState, useCallback } from "react";
import gsap, { TimelineLite } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { Link } from "react-router-dom";

import logo_white from "../../assets/images/logo-white.svg";
import logo_gradient from "../../assets/images/logo-gradient.svg";

export default function Header(props) {
    const logo1Ref = useRef(null);
    const logo2Ref = useRef(null);

    const [isSticky, setIsSticky] = useState(false);


    const buttonRef = useRef(null);

    const [dropdown1Open, setDropdown1Open] = useState();
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleDropdown1 = () => {
        setDropdown1Open(!dropdown1Open);
    };

    const handleReloadClick = () => {
        window.location.href();
        window.location.reload();
    };


    const handleScroll = useCallback(() => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const firstSection = document.getElementById('cx_connecting');

        const isMobile = window.innerWidth < 768; // Assuming mobile devices have a max width of 768px

        if (firstSection != null) {
            if (scrollTop >= firstSection.offsetTop + 750) {
                setIsScrolled(true);
            }
        }

        if (props.headerType === 'black') {
            const logo = document.querySelector('.cx_nav-logo');
            const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScroll > 300 && isMobile) {
                // Scrolling down
                logo.classList.add('hide');
            } else {
                // Scrolling up
                logo.classList.remove('hide');
            }

        } else {
            if (window.pageYOffset > 200) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }
    }, [props.headerType]);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const Timeline = new TimelineLite();
        let ctx = gsap.context(() => {
            if (dropdown1Open) {
                const overlay_nav = gsap.timeline({
                    defaults: {
                        delay: 0,
                        duration: 1,
                        ease: "Power4.easeInOut",
                    },
                });
                overlay_nav.to(
                    ".cx_mobile-overlay",
                    { height: "100vh", willChange: "transform", duration: 1 },
                    "-=.1"
                );

                gsap.from(".cx_nav-logo_mobile", {
                    opacity: 0,
                    delay: 0.5,
                    willChange: "transform",
                    ease: "Power2.out",
                    duration: 1,
                });

                Timeline.staggerFrom(
                    ".cx_navbar-menu",
                    2,
                    {
                        y: 0,
                        opacity: 0,
                        delay: 0,
                        willChange: "transform",
                        ease: "Power4.easeInOut",
                    },
                    0.2
                );
            } else {
                if (!dropdown1Open) {
                    const overlay_nav = gsap.timeline({
                        defaults: {
                            delay: 0,
                            duration: 1,
                            willChange: "transform",
                            ease: "Power4.easeInOut",
                        },
                    });
                    overlay_nav.from(
                        ".cx_mobile-overlay",
                        { height: "100vh", willChange: "transform", duration: 1 },
                        "-=.1"
                    );
                }
            }
        });

        const isMobile = window.innerWidth < 768; // Assuming mobile devices have a max width of 768px

        const trigger = isMobile ? ".cx_hero-title" : ".cx_connecting-section";

        const start = isMobile ? "200px top" : "top 100px";

        const triggerOffset = window.innerHeight / 2;
        if (trigger) {
            const showFirstLogoTrigger = {
                trigger,
                start,
                delay: 0,
                end: `+=${triggerOffset}`,
                onEnter: () => {
                    gsap.to(logo1Ref.current, { opacity: 0 });
                },
                onLeaveBack: () => {
                    gsap.to(logo1Ref.current, { opacity: 1 });
                },
            };

            ScrollTrigger.create(showFirstLogoTrigger);
        }


        const section_inner = document.querySelector(".cx_content-section");
        const triggerOffset_inner = window.innerHeight / 2;
        if (section_inner) {
            const showFirstLogoTrigger = {
                trigger: section_inner,
                start: "20 top",
                delay: 0,
                duration: 0,
                end: `+=${triggerOffset_inner}`,
                onEnter: () => {
                    gsap.to(logo2Ref.current, { opacity: 0 });
                },
                onLeaveBack: () => {
                    gsap.to(logo2Ref.current, { opacity: 1 });
                },
            };

            ScrollTrigger.create(showFirstLogoTrigger);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            ctx.revert();
        };

    }, [dropdown1Open, handleScroll]);




    return (
        <header className={`cx_main-header cx_main-header--${props.headerType} ${isSticky ? "cx_main-header--scrolled" : ""} ${dropdown1Open ? "cx_main-header--menuOpen" : ""}`} >
            <nav className={`cx_navbar ${dropdown1Open ? "menu-open" : ""}`}>
                {props.headerType === "light" && (
                    <div className={`cx_nav-logo ${isScrolled ? '' : 'hide'}`} >
                        <Link to="/" className="scrollToTop">
                            <img src={logo_white} alt="Logo White" ref={logo1Ref} />
                        </Link>
                    </div>
                )}
                {props.headerType === "dark" && (
                    <div className="cx_nav-logo">
                        <Link to="/" className="scrollToTop">
                            <img src={logo_gradient} alt="Logo Gradient" ref={logo2Ref} />
                        </Link>
                    </div>
                )}
                {props.headerType === "black" && (
                    <div className="cx_nav-logo">
                        <Link to="/" className="scrollToTop">
                            <img src={logo_gradient} alt="Logo Gradient" ref={logo2Ref} />
                        </Link>
                    </div>
                )}
                <div className="cx_nav-logo_mobile">
                    <Link to="/" className="scrollToTop" onClick={handleReloadClick}>
                        <img src={logo_gradient} alt="Logo Gradient" />
                    </Link>
                </div>

                <ul className="cx_navbar-menu cx_navbar-menu-mobile">
                    <li>
                        <Link
                            className="cx_navbar-menu__link home_target"
                            onClick={toggleDropdown1}
                            to="/#home"
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="cx_navbar-menu__link about_target"
                            onClick={toggleDropdown1}
                            to="/#about"
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="cx_navbar-menu__link approach_target"
                            onClick={toggleDropdown1}
                            to="/#approach"
                        >
                            Approach
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="cx_navbar-menu__link"
                            to="/casestudy/salt"
                        >
                            Work
                        </Link>
                    </li>
                    <li>
                        <Link
                            className="cx_navbar-menu__link"
                            to="mailto:hello@chapterx.io"
                        >
                            Contact
                        </Link>
                    </li>
                </ul>

                <button
                    ref={buttonRef}
                    type="button"
                    className={`cx_humberburg-button ${dropdown1Open ? "active" : ""
                        }`}
                    onClick={toggleDropdown1}
                >
                    <span className="cx_humberburg__line cx_humberburg__line--top"></span>
                    <span className="cx_humberburg__line cx_humberburg__line--bottom"></span>
                </button>
            </nav>
            <div className="cx_mobile-overlay"></div>
        </header>
    );
}
