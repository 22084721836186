import React, {useState, useEffect} from 'react';
import Lottie from 'lottie-react';
import { Link } from "react-router-dom";
import animation1 from '../../assets/images/Desktop-Holding-01.json';
import animation2 from '../../assets/images/Desktop-Holding-02.json';
import animationMobile1 from '../../assets/images/Mobile-Holding-01.json';
import animationMobile2 from '../../assets/images/Mobile-Holding-02.json';

const Comingsoon = () => {
    const [opacity, setOpacity] = useState(0);
    const [opacity1, setOpacity1] = useState(1);
    
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOpacity(1);
            setOpacity1(0);
        }, 8000); // set the delay time in milliseconds here
        return () => clearTimeout(timeoutId);
    }, []);
    return (
        <section className="cx_holding-section">
            <div className="cx_holding-section-inner">
                <div className="cx_holding-icon-desktop">
                    <Lottie
                        className="cx_holding-lottie-first"
                        animationData={animation1}
                        autoplay={true}
                        loop={false}
                        style={{ opacity: opacity1 }}
                    />
                    <Lottie
                        style={{ opacity: opacity }}
                        className="cx_holding-lottie-two"
                        animationData={animation2}
                        autoplay={true}
                        loop={true}
                    />
                </div>

                <div className="cx_holding-icon-mobile">
                    <Lottie
                        className="cx_holding-lottie-first"
                        animationData={animationMobile1}
                        autoplay={true}
                        loop={false}
                        style={{ opacity: opacity1 }}
                    />
                    <Lottie
                        style={{ opacity: opacity }}
                        className="cx_holding-lottie-two"
                        animationData={animationMobile2}
                        autoplay={true}
                        loop={true}
                    />
                </div>

                <Link to="mailto:hello@chapterx.io" className="cx_holding-email">hello@chapterx.io</Link>
            </div>
        </section>
    )
}

export default Comingsoon