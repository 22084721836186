/** @format */

import React, { useLayoutEffect, useRef } from "react";
import gsap, { TimelineLite } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Layout from "../common/layout";
import useOrientation from "../landscape/useOrientation";
import LandscapePage from "../landscape/LandscapePage";

import SectionOne from "./SectionOne";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
// import SectionNine from "./SectionNine";

gsap.registerPlugin(ScrollToPlugin);

export default function Index(props) {

    const isLandscape = useOrientation();



    const component = useRef(null);

    useLayoutEffect(() => {
        const Timeline = new TimelineLite();

        let ctx = gsap.context(() => {
            // gsap.from(".cx_nav-logo", {
            //     opacity: 0,
            //     scale: 0,
            //     delay: 10,
            //     ease: "Power2.out",
            //     duration: 1,
            //     willChange: "transform", 
            //     force3D: true,
            // });

            gsap.from(".cx_humberburg-button", {
                opacity: 0,
                delay: 2,
                willChange: "transform",
                ease: "Power2.out",
                duration: 1,
            });

            gsap.fromTo(
                ".cx_navbar-menu",
                { autoAlpha: 0, x: -10, willChange: "transform", force3D: true, },
                { autoAlpha: 1, x: 0, duration: 1, willChange: "transform", force3D: true, }
            );

            Timeline.staggerFrom(
                ".cx_navbar-menu li",
                2,
                {
                    x: -20,
                    opacity: 0,
                    delay: 2,
                    ease: "expo.out",
                    willChange: "transform",
                    force3D: true,
                },
                0.2
            );
        }, component.current);

        return () => ctx.revert();
    }, []);

    if (isLandscape) {
        return <LandscapePage />;
    }

    return (
        <Layout headerType="light">
            <SectionOne data-speed="auto" />

            <SectionThree data-speed="auto" />

            <SectionFour data-speed="auto" />

            <div className="pinWrapper">
                <SectionFive data-speed="auto" />

                <SectionSix data-speed="auto" />
            </div>

            <SectionSeven data-speed="auto" />

            {/* <SectionNine data-speed="auto" /> */}

            <SectionEight data-speed="auto" />
        </Layout>
    );
}
