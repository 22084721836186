/** @format */

import React, { useEffect, useRef } from "react";
import { gsap, Sine } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function SectionFive(props) {
    const containerRef = useRef(null);
    // const oversizedWeRef = useRef(null);

    const contentSection5 = useRef(null);
    const contentContainer = useRef(null);

    const svgWeRef = useRef(null);
    const pathWeRef = useRef(null);
    const pathWe2Ref = useRef(null);
    const pathWe3Ref = useRef(null);
    const pathWe4Ref = useRef(null);
    const pathWe5Ref = useRef(null);
    const pathWe6Ref = useRef(null);
    const pathWe7Ref = useRef(null);

    useEffect(() => {
        //Bar Animation
        const content = contentSection5.current;
        const items = content.querySelectorAll("h2");
        let totalItemsHeight = 0;
        for (let i = 0; i < items.length; i++) {
            totalItemsHeight += items[i].clientHeight;
        }
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline({
                defaults: {
                    ease: "power2.out",
                    willChange: "transform",
                    force3D: true,
                },
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 20%",
                    scrub: true,
                    pin: true,
                    end: "+=" + (totalItemsHeight),
                    fastScrollEnd: totalItemsHeight,
                    invalidateOnRefresh: true,
                    toggleActions: "play pause resume reset",
                    anticipatePin: 1,
                },
            });

            timeline.fromTo(
                items[0],
                {
                    y: "100%",
                    willChange: "transform",
                    force3D: true,
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    willChange: "transform",
                    force3D: true,
                    ease: "none",
                }
            )
                .to(items[0], {
                    y: "-100%",
                    opacity: 0,
                    willChange: "transform",
                    force3D: true,
                    ease: "none",
                })
                .fromTo(
                    items[1],
                    {
                        y: "100%",
                        willChange: "transform",
                        force3D: true,
                        opacity: 0,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        willChange: "transform",
                        force3D: true,
                        ease: "none",
                    },
                    "-=.5"
                )

                .to(items[1], {
                    y: "-100%",
                    willChange: "transform",
                    force3D: true,
                    opacity: 0,
                    ease: "none",
                })
                .fromTo(
                    items[2],
                    {
                        y: "100%",
                        willChange: "transform",
                        force3D: true,
                        opacity: 0,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        willChange: "transform",
                        force3D: true,
                        ease: "none",
                    },
                    "-=.5"
                )
                .to(items[2], {
                    y: "-100%",
                    opacity: 0,
                    willChange: "transform",
                    force3D: true,
                    ease: "none",
                })
                .fromTo(
                    items[3],
                    {
                        y: "100%",
                        willChange: "transform",
                        force3D: true,
                        opacity: 0,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        willChange: "transform",
                        force3D: true,
                        ease: "none",
                    },
                    "-=.5"
                )
                .to(items[3], {
                    y: "-100%",
                    opacity: 0,
                    willChange: "transform",
                    force3D: true,
                    ease: "none",
                })
                .fromTo(
                    items[4],
                    {
                        y: "100%",
                        opacity: 0,
                        willChange: "transform",
                        force3D: true,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        ease: "none",
                        willChange: "transform",
                        force3D: true,
                    },
                    "-=.5"
                )
                .to(items[4], {
                    y: "-100%",
                    opacity: 0,
                    ease: "none",
                    willChange: "transform",
                    force3D: true,
                }).fromTo(
                    items[5],
                    {
                        y: "100%",
                        opacity: 0,
                        willChange: "transform",
                        force3D: true,
                    },
                    {
                        y: 0,
                        opacity: 1,
                        ease: "none",
                        willChange: "transform",
                        force3D: true,
                    },
                    "-=.5"
                )
                .to(items[5], {
                    y: "-50%",
                    opacity: 1,
                    ease: "none",
                    willChange: "transform",
                    force3D: true,
                });
            // const tl = gsap.timeline({
            //   scrollTrigger: {
            //     trigger: containerRef.current,
            //     start: "top top",
            //     end: "bottom center",
            //     scrub: true,
            //     pin: true,
            //   },
            // });

            gsap.to(svgWeRef.current, {
                y: -180,
                willChange: "transform",
                force3D: true,
                ease: "none",
                scrollTrigger: {
                    trigger: containerRef.current,
                    start: "top 40%",
                    end: "+=" + (totalItemsHeight),
                    scrub: true,
                },
            });

            gsap.to(pathWeRef.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=100%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe2Ref.current, {
                y: -350,
                x: 50,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=100%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe3Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=120%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });
            gsap.to(pathWe4Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=140%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });

            gsap.to(pathWe5Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=160%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });

            gsap.to(pathWe6Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=180%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });

            gsap.to(pathWe7Ref.current, {
                y: -350,
                willChange: "transform",
                force3D: true,
                ease: Sine.easeInOut,
                scrollTrigger: {
                    trigger: svgWeRef.current,
                    start: "top bottom",
                    end: "+=200%",
                    scrub: true,
                    toggleActions: "play pause resume reset",
                },
            });

        });
        return () => ctx.revert();
    }, []);

    useEffect(() => {
        // Add a resize event listener
        const handleResize = () => {
            ScrollTrigger.refresh(); // Refresh the ScrollTrigger instance
        };
        window.addEventListener('resize', handleResize);
        return () => {
            // Remove the resize event listener
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section id="cx_weare_section" className="cx_weare-section scroll-container" >
            <div className="cx_weare-section_wrapper" ref={containerRef}>
                <div className="cx_container" ref={contentContainer}>
                    <div className="cx_connection-texts-slider" ref={contentSection5}>
                        <h2 className="cx_title">We are</h2>
                        <h2 className="cx_title" style={{ opacity: 0, position: "absolute" }}>Independent.</h2>
                        <h2
                            className="cx_title"
                            style={{ opacity: 0, position: "absolute" }}
                        >
                            Strategists.
                        </h2>
                        <h2
                            className="cx_title"
                            style={{ opacity: 0, position: "absolute" }}
                        >
                            Writers.
                        </h2>
                        <h2
                            className="cx_title"
                            style={{ opacity: 0, position: "absolute" }}
                        >
                            Designers.
                        </h2>
                        <h2
                            className="cx_title"
                            style={{ opacity: 0, position: "absolute" }}
                        >
                            Experience<br />makers.
                        </h2>
                    </div>
                </div>

                <div className="cx_center-parallax-one-group" ref={svgWeRef}>
                    <div className="cx_parallax-particles" ref={pathWeRef}></div>
                    <div className="cx_parallax-particles" ref={pathWe2Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe3Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe4Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe5Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe6Ref}></div>
                    <div className="cx_parallax-particles" ref={pathWe7Ref}></div>
                </div>

            </div>
        </section>
    );
}
